 .ant-table-thead {
     height: 50px !important;
     flex-grow: 1;
     /* background-color: #f9fafb !important; */

     gap: 8px;
     padding: 16px !important;
     border-bottom: solid 1px #e4e7ec !important;
     background-color: #f9fafb;
 }

 .country-text {

     font-family: Inter;
     font-size: 14px;
     font-weight: 500;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.43;
     letter-spacing: normal;
     text-align: left;
     color: #454d59;
     margin-bottom: 6px;
 }


 .add-country-btn {
     height: 52px;
     width: 100%;
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     gap: 4px;
     padding: 16px;
     border-radius: 8px;
     border: solid 1px #e3e6ea;
     background-color: #fff;

 }

 .country-selected-label {
     font-family: Inter;
     font-size: 14px;
     font-weight: 500;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.43;
     letter-spacing: normal;
     text-align: left;
     color: #373b42;

 }

 .method-selected {
     font-family: Inter;
     font-size: 14px;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.43;
     letter-spacing: normal;
     text-align: left;
     color: #5d6c7d;
 }

 .add-country-text {
     font-family: Inter;
     font-size: 14px;
     font-weight: 500;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.43;
     letter-spacing: normal;
     text-align: left;
     color: #373b42;
 }

 .trash-container {
     cursor: pointer;
     height: 42px;
     width: 36px;
     flex-grow: 0;
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     gap: 8px;
     /* padding: 12px; */
     border-radius: 8px;
 }

 .row-white {
     background-color: white !important;
 }

 .method-issue {
     font-family: Inter;
     font-size: 14px;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.43;
     letter-spacing: normal;
     text-align: left;
     color: red;
 }