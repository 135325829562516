.order-fullfillment-container {
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    padding: 0px 10px;
}

.collapse-label-container {
    display: flex;
    align-items: center;
    gap: 12px;
}

.collapse-borders {
    border-radius: 12px;
    border: solid 1px #cad1d7 !important;
    background-color: #fff;
    margin-bottom: 16px;
}

.product-img-container {
    width: 60px;
    height: 60px;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0;
    border-radius: 8px;
    border: 1px solid lightgrey;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.08);
    background-color: #fdfdfd;
}

.order-id {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #3e444c;
    margin-bottom: 8px;

}

.arrow-icon-180 {
    width: 11px;
    height: 12px;
}

.collapse-product-name {
    width: 200px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #3e444c;
    margin-bottom: 8px;
}

.order-details-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #4b5563;
    margin-bottom: 4px;

}

.attention-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #b42318;
}

.attention-container {
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #fef3f2;
}

.collapse-label-description {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #5d6c7d;
}

.steps-description-container {
    width: 90%;
    margin-bottom: 35px;
}

.flex-container-gap {

    display: flex;
    align-items: center;
    gap: 8px;
}

.variant-dropdown-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 8px;
    padding: 0 10px;
}

.import-btn {
    min-width: 74px;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #e3e6ea;

    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    text-align: left;
    color: #344054;
}

.steps-heading-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #344054;
    margin-bottom: 16px;
}

.variant-label-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #344054;
    /* border: 1px solid red; */
    padding: 5px 10px;
    border-radius: 8px;
    background-color: #f2f4f7;
}

 
.replaced-low-stock {
    height: 34px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #ecfdf3;
}
 
.out-of-stock {
    /* width: 115px; */
    height: 34px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #fef3f2;

}

.out-of-stock p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #b42318;
}

.replaced-low-stock p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #027a48;
}

.checkbox-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #344054;
    cursor: pointer;
}

.replace-variant-btn-shipping {
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    /* margin: 32px 2px 12px 41.5px; */
    padding: 8px 14px;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    border: solid 1px #e3e6ea;
    background-color: #f4f6f7;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #3e444c;
}

.replace-variant-btn {
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    border: solid 1px var(--primary-color);
    background-color: var(--primary-color);
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.ant-table-expanded-row {
    display: none !important;
}

.shipping-not-avail-heading {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #3e444c;
}

.shipping-not-avail-para {
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: #5d6c7d;
}

.error-message-shipping {
    margin-top: 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #eb5757;
}