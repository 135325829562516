 
.icon-bg-light-blue{
    background-color: #EDE9FE;
    border-radius:50% ;
}
.icon-bg-light-grey{
    background-color: #F5F5F5;
    border-radius:50% ;

}
.subscription-monthly-borders{
   border-top-left-radius: 9999px; 
   border-bottom-left-radius: 9999px; 
}
.subscription-yearly-borders{
   border-top-right-radius: 9999px; 
   border-bottom-right-radius: 9999px; 
}
.subscription-cards-container{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3,1fr);
}