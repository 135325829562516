 .loader-class {
   aspect-ratio: 1;
   border-radius: 50%;
   -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
   animation: l13 1s infinite linear;
 }

 @keyframes l13 {
   100% {
     transform: rotate(1turn)
   }
 }