.variant-pricing-table-main-container {
  padding: 86px 120px 120px 120px;
  background-color: white;
}

.variant-pricing-para {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #5d6c7d;
  margin-bottom: 24px;

}

.variant-pricing-heading {
  /* width: 108px; */
  height: 24px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
  margin-bottom: 8px;

}

.variant-select-styles {
  width: 158px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 10px 12px 10px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #cad1d7;
  background-color: #fff;
}

:where(.css-dev-only-do-not-override-kghr11).ant-table-wrapper .ant-table-row-expand-icon::before,
:where(.css-dev-only-do-not-override-kghr11).ant-table-wrapper .ant-table-row-expand-icon::after {
  content: none !important;
}

.sku-image-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #cad1d7;
  flex-grow: 0;
  background-color: #d9d9d9;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;


}

.sku-image-container-disable {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  opacity: 0.5;
  border-radius: 8px;
  background-color: #d9d9d9;
}

.sku-and-image-container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.pricing-table-eye-styles {
  width: 18.3px;
  height: 13.3px;
  margin: 0;
  cursor: pointer;
}

.pricing-table-subrow-style {
  display: flex;
  gap: 8px;
  border-bottom: solid 1px #e3e6ea;
  /* flex gap-2 mb-1 */
}

.pricing-table-input-field {
  /* width: 150px ; */
  height: 34px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #cad1d7;
  background-color: #fff;
}

.pricing-table-input-field:hover {
  outline: 5px solid var(--outline-color) !important;
  border: 1px solid var(--border-color) !important;
}

.pricing-table-input-field:hover {
  outline: 5px solid var(--outline-color) !important;
  border: 1px solid var(--border-color) !important;
}

.pricing-table-input-field .ant-input-suffix {
  display: flex;
  align-items: center;
  justify-content: center;   
}

.other-pricing-text {

  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #3e444c;
}

.antd-override-input-props {
  /* width: 50% !important; */
  border: none !important;
}

.antd-override-input-props:focus-within {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;

}

.antd-override-input-props:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.childprice-input {
  width: 100% !important;
  border: none !important;
}

.childprice-input:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.childprice-input:focus-within {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

/* .active-row {
  background-color: #fbfffc !important; 
  color: black; 
} */
.inactive-input {
  border: 1px solid #E4E7EA;
  width: 150px !important;
  height: 34px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 8px 14px 8px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.inactive-row {
  color: #9EA1A5 !important;
  /* cursor: not-allowed; */

}

.other-pricing-text-disable {
  flex-grow: 0;
  opacity: 0.5;
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #3e444c;
}

.sku-img-styles {
  width: 100%;
  height: 38px;
  border-radius: 7px;
  /* border:solid 1px #cad1d7; */
}

.ant-table-row-expand-icon {
  /* left:3150%;
  z-index: 2; */
  background-color: transparent !important;
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spin buttons in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-table-thead>tr>td {

  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.29 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #475467 !important;
}


.ant-table-thead>tr>th {
  padding: 8px 16px !important;
}

.ant-table-row-level-1 {
  background-color: white;
}

/* .variants-pricing-table{
  border-radius: 8px !important;
  border: solid 1px #e3e6ea !important;
} */
.ant-table-content {
  border: 0;
}


@media screen and (max-width:1024px) {
  .variant-pricing-table-main-container {
    padding: 86px 10px 120px 10px;
  }
}