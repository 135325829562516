.email-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 384px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  padding: 9px 13.2px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
}

.email-input:focus {
  outline: var(--input-outline-color);
  outline-width: 12px;
  outline-style: auto;
  border: 1px solid var(--darkblue-color) !important;
}

.main-container {
  display: flex;
  display: flex;
}

.sidebar {
  display: flex;
  width: 248px;
  height: 100vh;
  padding: 50px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  flex-shrink: 0;
  border-right: 1px solid #cad1d7;
  background: #fff;
}

.side-bar-logo {
  display: flex;
  height: 39px;
  width: 200px;
  justify-content: start;
  align-items: center;
  gap: 8px;
}

.logo {
  display: flex;
  height: 39px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  height: 39px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.active-button {
  cursor: pointer;
  display: flex;
  width: 200px;
  padding: 8px 12px;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  background-color: #f4f6f7;
}

.white-icon {
  width: 12px;
  height: 13.3px;
  /* color: solid 1.5px #fff; */
  /* fill: #fff; */
  stroke: #fff;
}

.active-button span {
  width: 85px;
  height: 24px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  
}

.non-active-button {
  cursor: pointer;
  display: flex;
  width: 200px;
  padding: 10px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
}

.non-active-button span {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #667085;
}

.gray-icon {
  width: 13.3px;
  height: 13.3px;
  /* color: solid 1.5px #667085; */
  /* fill: #667085; */
  stroke: #667085;
}

.main-content {
  margin-left: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.nav-dashboard {
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #ffffff);
  display: flex;
  justify-content: space-between;
  padding: 20px 50px 19px 24.2px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #ffffff);
  display: flex;
  justify-content: space-between;
  padding: 20px 50px 19px 24.2px;
  margin-bottom: 16px;
}

.nav-left {
  color: #1d2939;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #1d2939;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

/* other Integrations */
.resources-card {
  width: 264px;
  height: 160px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 3px 4px 20px 0 rgba(112, 112, 112, 0.1);
  border: solid 1px #eaecf0;
  background-color: #fff;
}

.task-container2 {
  width: auto;
  height: 804px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 0;
}

.Dashboard-Cards2 {
  height: auto;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  gap: 24px;
  padding: 0;
}

.integrations-name {
  width: 71px;
  height: 24px;
  flex-grow: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  padding: 0;
}

/* Show the checkmark when the checkbox is checked */
input[type="checkbox"]:checked + .custom-checkbox::before {
  /* content: "✔"; */
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M1.73 12.91l6.37 6.37L22.79 4.59" stroke="%236595fb" stroke-width="4"/></svg>') !important;
  color: #6595fb;
  font-weight: bold;
}

input[type="checkbox"]:not(:checked) + .integrations-name {
  justify-content: center;
}

input[type="checkbox"] {
  display: none;
}

.buttons-integration {
  height: 80px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 0;
}

img.plus {
  width: 16px;
  height: 16px;
  flex-grow: 0;
  object-fit: contain;
  color: #6595fb;
}

.Tertiary-button {
  width: 200px;
  height: 20px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0;
  border-radius: 8px;
  border: none;
}

.Tertiary-button button {
  width: 170px;
  height: 20px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #6595fb;
  border: none;
  background-color: transparent;
}

.picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*other Integration page*/

.text-side {
  /* width: 456px; */
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  margin: 0 0 0 0;
  height: 40px;
  padding-top: 8px;
}

.choose-what-to {
  height: 48px;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #475467;
}

.secondary-Button {
  width: fit-content;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 8px;
  border: solid 1px #eaecf0;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #1d2939;
}

.secondary-Button img {
  width: 16px;
  height: 16px;
  flex-grow: 0;
  object-fit: contain;
}

.main-content {
  width: auto;
  height: 272px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 24px;
  padding: 0;
}

.progressbar-dashboard {
  border-radius: 100px;
  background: var(--Gray-200, #eaecf0);
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  height: 8px;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 0;
}

.left-container {
  display: flex;
  /* width: 88%; */
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
}

.Dashboard-Cards {
  height: 136px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
  padding: 0;
}

.error-card {
  min-width: 20%;

  max-width: 30%;

  flex-grow: 1;
  display: flex;
  flex-direction: column;

  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 3px 4px 20px 0 rgba(112, 112, 112, 0.1);
  border: solid 1px #eaecf0;
  background-color: #fff;
}

.card {
  width: 500px;

  flex-grow: 1;
  display: flex;
  flex-direction: column;

  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 3px 4px 20px 0 rgba(112, 112, 112, 0.1);
  border: solid 1px #eaecf0;
  background-color: #fff;
}

.icon-styles {
  width: 30px;
  height: 30px;
}

.head-container {
  height: 272px;
  display: flex;
}

.cards {
  height: 272px;
  width: 100%;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 3px 4px 20px 0 rgba(112, 112, 112, 0.1);
  border: solid 1px #eaecf0;

  background-color: #fff;
}

.todo-container {
  width: auto;
  height: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0;
}

.icon-bg-file {
  width: 32px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 2px;
  background-color: #f5faff;
}

.icon-bg-file img {
  width: 16px;
  height: 16px;
  color: #6595fb;
  color: #6595fb;
}

.number-date {
  height: 32px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.Total {
  color: var(--Gray-800, #1d2939);
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.Date {
  width: 73px;
  height: 14px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #667085;
}

.General-progress {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
  box-shadow: 3px 4px 20px 0px rgba(112, 112, 112, 0.1);
  border: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
  box-shadow: 3px 4px 20px 0px rgba(112, 112, 112, 0.1);
}

.General-progress .container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.General-progress .container .pic-container {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
}

.General-progress .container .pic-container img {
  width: 16px;
  height: 16px;
}

.progress-container {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.progress-container .progress {
  height: 8px;
  width: 100%;
  border: solid 1px #e9ecef;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

.General-progress span {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: var(--gray-500);
}

.tasks-overall {
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 16px;
  border-radius: 8px;
}

.task-detail {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  flex-direction: column;
}

.icon-bg-file {
  width: 32px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 2px;
  background-color: #f5faff;
}

.icon-bg-file img {
  width: 16px;
  height: 16px;
  color: #6595fb;
}

.icon-bg-clock {
  width: 32px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 2px;
  background-color: #fcf9f7;
}

.icon-bg-clock img {
  width: 16px;
  height: 16px;
  /* color: #6595fb; */
}

.icon-bg-check {
  width: 32px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 2px;
  background-color: #effffb;
}

.icon-bg-check img {
  width: 16px;
  height: 16px;
  color: #52ac97;
}

.To-do {
  color: var(--Gray-600, #475467);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
}

.number-date {
  height: 32px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.Total {
  color: var(--Gray-800, #1d2939);
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.tasks-overall .container {
  height: 140px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 17px;
  padding: 0;
}

.todo {
  display: flex;
  width: 151px;
  height: 20px;
  align-self: stretch;
  flex-grow: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.shape-text {
  display: flex;
  width: 160px;
  justify-content: left;
  align-items: center;
  gap: 8px;
}

.shape-text img {
  width: 16px;
  height: 16px;
}

.shape-text span {
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.todo span {
  color: var(--Gray-800, #1d2939);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.chart {
  height: 140px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.task-container {
  width: auto;
  margin-top: 56px;
  height: 804px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 24px;
  padding: 0;
}

.todo-block {
  width: 360px;
  height: 744px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  padding: 0 0 8px;
  border: solid 1px #eaecf0;
  background-color: #f2f4f7;
}

.todo-header {
  height: 60px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
  gap: 8px;
  padding: 16px 8px;
  box-shadow: 3px 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaecf0;
  background-color: #fff;
}

.todo-text {
  width: 49px;
  height: 28px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  display: contents;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #286dff;
}

.todo-card {
  width: 100%;
  height: 138px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 13px;
  border-radius: 8px;
  border: solid 1px #eaecf0;
  background-color: #fff;
  margin-bottom: 8px;
}

.company {
  height: 54px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0;
}

.company-details {
  height: 54px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
}

.company-name {
  height: 32px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.company-name span {
  color: var(--Gray-900, #101828);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.more-horizontal {
  width: 32px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 4px;
  border-radius: 4px;
}

.company-undertext {
  width: 100%;
  height: 14px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 0;
  border-radius: 100px;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  /* letter-spacing: normal; */
  text-align: left;
  color: #344054;
}

.date-text {
  width: 92px;
  height: 14px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  display: flex;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #667085;
}

.link-buttons {
  height: 36px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
}

.Secondary-button {
  height: 36px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 0px;
  border-radius: 8px;
  border: solid 1px #eaecf0;
  background-color: #fff;
}

.continer-data-set {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.link-buttons span {
  width: 114px;
  height: 20px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #1d2939;
}

.input-field2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  box-sizing: border-box;
  padding-top: 8px;
  gap: 8px;
}

.Supporting-text {
  height: 40px;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-align: left;
  color: #667085;
  margin-top: 8px;
}

.heading-data {
  height: 28px;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #101828;
}

.Tertiary-button {
  width: 86px;
  height: 20px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding-top: 15px;
  border-radius: 8px;
  border: none;
  background-color: white;
  color: var(--Gray-800, #1d2939);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.inprogress-text {
  width: 49px;
  height: 28px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  display: contents;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: var(--orange-color);
}

.completed-text {
  width: 49px;
  height: 28px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  display: contents;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: var(--Green-color);
}

.task-blocks-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  width: 100%;
}

.modal-dark {
  backdrop-filter: blur(var(--background-blurmd, 8px));
  background: rgba(52, 64, 84, 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
}

.inside-layout-main {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.pending-text {
  font-weight: 600;
  font-size: 16px;
  color: #f29339;
}

.success-text {
  font-weight: 600;
  font-size: 16px;
  color: #3ac430;
}

.table-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 90px;
  max-width: auto;
  height: 40px;
  margin-bottom: 0 !important;
}

.order-disable-btn {
  background-color: #efeded !important;
  color: grey !important;
  cursor: not-allowed !important;
}
