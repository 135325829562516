.orders-table-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 24px;
}

.orders-number-container {
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  border-radius: 24px;
  background-color: #f2f4f7;
  margin-left: 5px;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
}
.orders-number-container.error {
  background-color: #fef3f2;
  opacity: 0.66;
  color: #b42318;
}

.tab-label-container {
  display: flex;
  align-items: center;
}
.tab-label-container div {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
}

.ant-tabs-ink-bar,
.ant-tabs-ink-bar-animated {
  background-color: #6941c6 !important;
}

.filter-btn-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0px 24px 0px;
}

.filter-btn-row .actions-btn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #3e444c;
  padding: 10px 16px;
  border: solid 1px #cad1d7;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.ant-dropdown-menu {
  box-shadow: 0px 4px 6px -2px #10182808 !important;
  box-shadow: 0px 12px 16px -4px #10182814 !important;
  background: #ffffff !important;
  border-radius: 8px !important;
  border: 1px solid #e3e6ea !important;
}

.ant-dropdown-menu-item {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  text-align: left !important;
  color: #3e444c !important;
  padding: 10px 16px !important;
}

.import-order-btn {
  width: 158px;
  height: 38px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #7f56d9;
  background-color: #7f56d9;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.import-order-btn:hover {
  background-color: #6941c6;
}

.search-input {
  width: 320px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 14px 8px 38px;
  border-radius: 8px;
  border: solid 1px #cad1d7;
  background-color: #fff;
  box-sizing: border-box;
  background-image: url("../../../assets/search-icon.svg");
  background-repeat: no-repeat;
  background-position: 12px 50%;
  color: #3e444c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter", sans-serif;
}
.search-input:focus {
  border: solid 1px #6941c6;
  outline: none;
  box-shadow: none;
}
.search-input::placeholder {
  color: #5d6c7d;
}

.ant-table-thead th {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #475467 !important;
}

/* Custom styles for table body text */
.ant-table-tbody td {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #3e444c;
  border-bottom: 1px solid #264f78;
}

/* no data component styling */

.no-data-component-main-container {
  height: 302px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-data-component-main-container h4 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #373b42;
}

.no-data-component-main-container p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #4b5563;
}

.modal-import-button {
  width: 190px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 0px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #7f56d9;
  background-color: #7f56d9;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.modal-import-button-disabled {
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;
  border: solid 1px #7f56d9 !important;
  background-color: #7f56d9 !important;
  opacity: 0.8 !important;
  color: #fff !important;
}

.modal-import-button:hover {
  border: solid 1px #6941c6 !important;
  background-color: #6941c6 !important;
}
.order-modal-cancel-button {
  width: 50%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 18px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #d0d5dd;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #344054;
}
.order-modal-cancel-button-disabled {
  width: 50% !important;
  height: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  padding: 10px 18px !important;
  border-radius: 8px !important;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;
  border: solid 1px #d0d5dd !important;
  background-color: #fff !important;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #344054 !important;
  opacity: 0.8 !important;
}

.order-modal-cancel-button:hover {
  color: #6941c6 !important;
  border: solid 1px #d0d5dd !important;
  background-color: #dddae3 !important;
}

.add-order-modal-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.modal-content-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.modal-content-container.wide {
  gap: 12px;
}

.modal-content-container h4 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}

.modal-content-container p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #475467;
}

.modal-input-label {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #344054;
}

.input-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
}
.form-control {
  height: 44px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 14px;

  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #d0d5dd;
  background-color: #fff;
}

.form-control:focus {
  border: solid 2px #6941c6;
  outline: none;
  box-shadow: none;
}

.add-order-modal-warning {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #eb5757;
  padding: 4px 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.custom-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.custom-spin-icon {
  font-size: 64px;
  color: #6941c6;
}

.order-status {
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 16px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: center;
  justify-content: left;
}
.order-status:has(div.button) {
  cursor: pointer;
  padding: 4px 8px 4px 12px;
}

.order-status.blocked {
  background: #fef3f2;
  color: #b42318;
}
.order-status.pending {
  background: #eff8ff;
  color: #175cd3;
}
.order-status.payment {
  background: #fffaeb;
  color: #b54708;
}
.order-status.shipment {
  background: #f9f5ff;
  color: #6941c6;
}
.order-status.shipped,
.order-status.cancelled,
.order-status.unknown {
  background: #f2f4f7;
  color: #344054;
}
.order-status.delivered {
  background: #ecfdf3;
  color: #027a48;
}

.order-status.blocked .circle-icon,
.order-status.blocked .arrow-icon {
  filter: brightness(0) saturate(100%) invert(32%) sepia(84%) saturate(1450%)
    hue-rotate(337deg) brightness(95%) contrast(97%);
}
.order-status.pending .circle-icon,
.order-status.pending .arrow-icon {
  filter: brightness(0) saturate(100%) invert(60%) sepia(75%) saturate(4587%)
    hue-rotate(192deg) brightness(100%) contrast(97%);
}
.order-status.payment .circle-icon,
.order-status.payment .arrow-icon {
  filter: brightness(0) saturate(100%) invert(60%) sepia(21%) saturate(5028%)
    hue-rotate(1deg) brightness(103%) contrast(94%);
}
.order-status.shipment .circle-icon,
.order-status.shipment .arrow-icon {
  filter: brightness(0) saturate(100%) invert(28%) sepia(20%) saturate(5998%)
    hue-rotate(239deg) brightness(96%) contrast(83%);
}
.order-status.shipped .circle-icon,
.order-status.shipped .arrow-icon,
.order-status.cancelled .circle-icon,
.order-status.cancelled .arrow-icon,
.order-status.unknown .circle-icon,
.order-status.unknown .arrow-icon {
  filter: brightness(0) saturate(100%) invert(22%) sepia(25%) saturate(627%)
    hue-rotate(178deg) brightness(96%) contrast(91%);
}
.order-status.delivered .circle-icon,
.order-status.delivered .arrow-icon {
  filter: brightness(0) saturate(100%) invert(34%) sepia(19%) saturate(2632%)
    hue-rotate(111deg) brightness(98%) contrast(98%);
}

.order-status .button {
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 16px;
  border: 1px solid;
  line-height: 18px;
  font-size: 12px;
  font-weight: 500;
  background-color: #ffffff;
}
.order-status.blocked .button {
  border-color: #fecdca;
}
.order-status.pending .button {
  border-color: #b1caf5;
}
.order-status.payment .button {
  border-color: #fed6ca;
}
.order-status.shipment .button {
  border-color: #e1d2f9;
}
.order-status.shipped .button,
.order-status.cancelled .button,
.order-status.unknown .button {
  border-color: #d0d5dd;
}
.order-status.delivered .button {
  border-color: #d0d5dd;
}

.order-column-title-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.order-date {
  display: flex;
  flex-direction: column;
}
.order-date .days-count {
  color: #798797;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.order-id-link {
  font-weight: 500;
  cursor: pointer;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #7f56d9 !important;
  border-color: #7f56d9 !important;
}
.ant-table-row.ant-table-row-selected > .ant-table-cell {
  background-color: #f9f5ff !important;
}

.items-count-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  line-height: 28px;
}

.items-count-cell img {
  background-color: #fef3f2;
  padding: 4px;
  border-radius: 50%;
}
.ant-tooltip-inner {
  color: #b42318 !important;
  border-radius: 8px !important;
  border: 1px solid !important;
  border-color: #fecdca !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  padding: 10px 12px !important;
  line-height: 20px;
  box-shadow: 0px 4px 6px -2px #10182808 !important;
  box-shadow: 0px 12px 16px -4px #10182814 !important;
}

.row-white {
  background-color: white !important;
}
.more-select-orders {
  border: none !important;
}

.language-selector-dropdown {
  max-width: 160px;
}

.language-selector-dropdown:hover {
  color: #6941c6 !important;
  border: 1px solid #6941c6 !important;
}

.language-selector-dropdown .ant-space {
  width: 100%;
  justify-content: space-between;
}

.language-selector-dropdown .ant-space-item,
.language-selector-dropdown .anticon,
.language-selector-dropdown svg {
  transition: none !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #efe4ff !important;
}
