.image-section-main-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
  border-radius: 2px;
}

.imge-seciton-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.gallery-main-image {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 20px;
  border-radius: 2px;
}
.gallery-thumbnails-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.gallery-thumbnails-wrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
}

.gallery-thumbnails {
  display: flex;
  width: 100%;
  gap: 10px;
  padding-bottom: 10px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.arrow-button {
  height: 100px;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s;
}

.arrow-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.arrow-button:hover:not(:disabled) {
  background-color: #c9c4c4;
}
