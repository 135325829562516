.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 16px;
  background-color: #f9fafb;
}

.rows-per-page {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.rows-per-page span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #4b5563;
}

.pagination-form-control {
  width: 100px;
}

.ant-pagination-item-active {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
  border: solid 1px #7f56d9 !important;
  background-color: #fff !important;
  color: #7f56d9 !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #7f56d9 !important;
}

.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-selector.ant-select-selector-focused {
  box-shadow: none !important;
  outline: 5px solid var(--outline-color) !important;
  border:  1px solid var(--border-color)!important;
}
/* .ant-select-open, .ant-select-outlined, .ant-select, .ant-select:focus, .ant-select-open:focus
{
  box-shadow: none !important;
  outline: 5px solid var(--outline-color) !important;
  border:  1px solid var(--border-color)!important;
 } */
.ant-select-selector.ant-select-selector:hover{
  box-shadow: none !important;
  outline: 5px solid var(--outline-color) !important;
  border:  1px solid var(--border-color)!important;
}