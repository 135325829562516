body {
  font-family: "Inter", sans-serif;
}


.wrapper {
  width: 80%;
  margin: 0;
}
.image-paddings {
  padding: 5px;
}
.image_headings {
  padding: 20px 10px 10px 10px;
  font-size: 20px;
}
.connect-main-input {
  width: 75%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  box-sizing: border-box;
  gap: 4px;
}
.connect-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  padding: 9px 13.2px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
}
.connect-input:focus {
  outline: 4px solid var(--darkblue-color) !important;
}
fcon .navbar {
  overflow: hidden;
  background-color: var(--white-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-left a {
  cursor: pointer;
  display: inline-block;
  color: var(--gray800-color);
  text-align: center;
  padding: 6px 16px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  font-family: "Inter", sans-serif;
}
.navbar-left a:hover {
  font-weight: bold;
}
.navbar-right button {
  display: inline-block;
  text-align: center;
  padding: 5px 16px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.navlogo img {
  height: 32px;
  width: 108px;
  margin-top: 10px;
}

.login-btn {
  background-color: transparent;
  border: 1px solid #eaecf0;
  color: var(--gray800-color);
  border-radius: 8px;
  font-family: "Inter", sans-serif;
}

.login-btn:hover {
  border-color: #6595fb;
  color: var(--gray800-color);
}

.signup-btn {
  background-color: #6595fb;
  border: none;
  border-radius: 8px;
  color: var(--white-color);
  font-family: "Inter", sans-serif;
}

.signup-btn:hover {
  background-color: #8cb1fe;
}

/* End of nav */

.hero {
  background-image: url(./assets/header.png);
  background-size: cover;
  background-position: center;
  height: 800px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin: 0;
}

.hero h1 {
  color: var(--gray800-color);
  font-size: 48px;
  font-weight: 700;
  line-height: 1.5;
  font-family: "Inter", sans-serif;
}

/* Dropdown styling */
.dropdown-container {
  width: 50%;
  height: 178px;
  background: linear-gradient(
    to bottom right,
    rgba(101, 149, 251, 0.71),
    rgba(101, 149, 251, 1),
    rgba(0, 70, 218, 0.9)
  );
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
}

.dropdown-container p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
}

/* dropdown landing page hero section */

.dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(180deg);
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35%;
  transform: translateY(50%);
}

.dropdown-box {
  align-items: center;
  justify-content: center;
  margin: 0 auto 0 auto;
  display: block;
  min-width: 231.5px;
  width: 100% !important;
  max-width: 483px;
}

.wrapper-dropdown {
  position: relative;
  display: inline-block;
  min-width: 231.5px;
  width: 100% !important;
  max-width: 483px;
  padding: 20px 20px 0px 0px;
  min-height: 44px;
  border-radius: 8px;
  background: #fff;
  text-align: left;
  color: #000000;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
}

.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}

.arrow {
  margin-left: 10px;
  margin-right: 10px;
  float: right;
  rotate: 180deg;
}

.selected-display {
  margin-left: 20px;
}

svg {
  transition: all 0.3s;
}

.wrapper-dropdown::before {
  position: absolute;
  top: 50%;
  right: 16px;

  margin-top: -2px;

  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #fff transparent;
}

.rotated {
  transform: rotate(-180deg);
}

.wrapper-dropdown .dropdown {
  transition: 0.3s;
  position: absolute;
  top: 120%;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 99;
  border-radius: 8px;
  box-shadow: inherit;
  background: inherit;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0;
  visibility: hidden;
}

.wrapper-dropdown .dropdown li {
  padding: 0 8px;
  line-height: 45px;
  overflow: hidden;
}

.wrapper-dropdown .dropdown li:last-child {
  border-bottom: none;
}

.dropdown {
  padding: 0.5rem !important;
  opacity: 1;
  visibility: visible;
  border-radius: 8px;
}

.wrapper-dropdown .dropdown li:hover {
  background-color: var(--gray200-color);
  border-radius: 10px;
}

.wrapper-dropdown.active {
  opacity: 1;
  visibility: visible;

  border-radius: 8px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-value {
  flex: 0 0 auto;
}

.content {
  flex: 1 1 auto;
}
.content-container {
  padding: 0 20px;
}

.button-82 {
  flex: 0 0 auto;
  color: var(--gray900-color);
}
.wrapper-dropdown .dropdown li .item :hover {
  color: var(--blue-color);
}
.button-82 img {
  width: 16px;
  height: 16px;
  flex: auto;
}

.arrow {
  width: 24px; /* Adjust width and height as needed */
  height: 24px;
  stroke: currentColor; /* Inherit color from surrounding element */
}

.wrapper-dropdown:hover .arrow {
  stroke: var(--blue-color);
}
.center-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 197px;
  margin-bottom: 200px;
  width: 100%;
  box-sizing: border-box;
}

.About-Us img {
  /* width: 565px; */
  height: 527px;
  position: absolute;
  right: -229px;
  top: 870px;
}

.about-text {
  display: flex;
  width: 697px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin: 100px 0px 56px 0px;
}

.about-heading {
  color: var(--Gray-900, #101828);
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  margin-bottom: 15px;
}
.about-supporting-text {
  display: flex;
  width: 77%;

  padding: 8px 0px 8px 16px;
  align-items: center;
  gap: 8px;
  border-left: 2px solid var(--Blue, #6595fb);
}
.why-supporting-text {
  display: flex;
  width: 70%;

  padding: 8px 0px 8px 16px;
  align-items: center;
  gap: 8px;
  border-left: 2px solid var(--Blue, #6595fb);
}
.main-container1 {
  padding: 0 0 200px 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  box-sizing: border-box;
}

.text {
  padding-left: 5px; /* Add space for the line */
}
.business-model {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.about-card-container {
  box-shadow: inset 0px 4px 10px 0px rgba(101, 149, 251, 0.05);
  border-radius: 8px;
  background: #ffffff;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;
  box-sizing: border-box;
  height: 500px;
  width: 30%;
  justify-content: center;
  border: 1.5px solid rgba(101, 149, 251, 0.1);
  background: var(--White, #fff);
  padding: 24px;
}
/* .Simplifying-Business2 {
  box-shadow: inset 0px 4px 10px 0px rgba(101, 149, 251, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(101, 149, 251, 0.1);
  background: #ffffff;
  margin-right: 32px;
  display: flex;
  border: 1px solid red;

  flex-direction: column;
  padding-bottom: 48px;
  box-sizing: border-box;
  height: 500px;
  width: 384px;
  padding: 0px 24px 24px 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
} */
.simplifying-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.simplifying-heading {
  align-self: stretch;
  color: var(--Gray-800, #1d2939);
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.heading-under-text {
  color: var(--Gray-500, #667085);
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  align-self: stretch;
}
.simplifying-undertext {
  color: var(--Gray-500, #667085);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  align-self: stretch;
}

.illustration {
  border-radius: 8px 8px 0 0;
  background: url("https://fn12.com/api/clusters/cluster-fn12-prod-europe-west1/projects/HXE3vxa8Jkue3CtrQhxYnN/assets/images/0074ae0cb6b44ceda9acfcc8f3ffd85ff5d969d1?Expires=1711963391&KeyName=fn12-cdn&Signature=GjwvSa0CJqliiLLTLPNzKoR6WCI=")
    50% / cover no-repeat;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* padding: 21.5px 33px; */
  width: 100%;
  height: 295px;
  box-sizing: border-box;
}

.illustrations {
  border-radius: 8px 8px 0 0;
  background: none;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 21.5px 33px;
  width: 384px;
  height: 295px;
  box-sizing: border-box;
}

.About-Us {
  position: relative;
  background: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
/*container 2 */
.center-container2 {
  margin-bottom: 200px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: inherfitit;
  justify-content: center;
}

.Why-Bizaibo {
  /* margin-right: 32px; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.heading-text {
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: fit-content;
  box-sizing: border-box;
}
.why-model {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.card-1 {
  display: flex;
  width: 48%;
  height: auto;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1.5px solid rgba(101, 149, 251, 0.1);
  background: var(--White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(101, 149, 251, 0.05) inset;
}
.control {
  margin-bottom: 16px;
  align-self: flex-start;
  width: 32px;
  height: 32px;
}
.side-image {
  opacity: 0.86;
  border-radius: 8px 0 0 8px;
  border-left: 2px solid rgba(101, 149, 251, 0.1);
  border-top: 2px solid rgba(101, 149, 251, 0.1);
  border-bottom: 2px solid rgba(101, 149, 251, 0.1);
}
.blue-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container-model {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.container-flex {
  display: flex;
}

/*Container3 */

.center-container3 {
  /* margin-right: 112px; */
  gap: 56px;
  display: flex;
  padding-bottom: 200px;
  align-items: center;
  flex-direction: column;
  width: inherit;
  justify-content: center;
}

.testimonial-heading-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.testimonials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 32px;
  width: fit-content;
  box-sizing: border-box;
  position: relative;
}
.testimonial-card {
  box-shadow: inset 0px 4px 10px 0px rgba(101, 149, 251, 0.05);
  border-radius: 8px;
  /* width: 50%; */
  height: 320px;

  border: 1.5px solid rgba(101, 149, 251, 0.1);
  background: var(--white, #ffffff);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22.5px;
  box-sizing: border-box;
}
.google-icon {
  display: flex;
  margin-bottom: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 336px;
  box-sizing: border-box;
}
.google {
  width: 32px;
  height: 32px;
}
.star {
  width: 24px;
  height: 24px;
  fill: var(--Blue-600, #6595fb);
}
.card-text {
  display: inline-block;
  overflow-wrap: break-word;
  align-self: stretch;
  color: var(--Gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 100px;
  overflow: hidden;
}
.person-pic {
  width: 48px;
  height: 48px;
  border-radius: 100px;
  border: 3px solid #fff;
  /* background: url(<path-to-image>) lightgray 50% / cover no-repeat; */
  box-shadow: 0px 4px 6px 0px rgba(0, 63, 168, 0.1);
}
.name-detail {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.name {
  align-self: flex-start;
  overflow-wrap: break-word;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
  color: var(--gray-900, #101828);
}
.designation {
  align-self: flex-start;
  overflow-wrap: break-word;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-600, #475467);
}
.time {
  color: var(--Gray-600, #475467);
  text-align: right;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.person {
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

/* Footer */
.foot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: fit-content;
  box-sizing: border-box;
}
.footer {
  padding: 100px 0px 0px;
  background-color: var(--Gray-50, #f9fafb);
}

.footer .widget1 p {
  font-size: 16px;
  color: #101828;
  margin-top: 26px;
}

footer .logo {
  max-width: 200px;
}

.footer h5 {
  font-weight: 500;
  margin-bottom: 28px;
  text-transform: capitalize;
  font-weight: 600;
  color: #101828;
  font-size: 21px;
}

.footer .widget2 .media img {
  margin-right: 20px;
  max-width: 100px;
}
.footer .widget2 .media p {
  font-size: 16px;
  color: #101828;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  line-height: 26px;
}

.footer .widget2 .media span {
  font-size: 12px;
  color: #101828;
  text-transform: uppercase;
  margin-top: 15px;
  display: block;
}

.footer .widget2 .media {
  margin-bottom: 20px;
}
.footer .widget3 ul li {
  list-style: none;
}
.footer .widget4 ul li {
  list-style: none;
}

.footer .widget3 ul li a,
.footer .widget4 ul li a {
  font-size: 16px;
  color: #101828;
  text-transform: capitalize;
  margin-bottom: 13px;
  display: block;
  text-decoration: none;
}

.footer .widget3 h5 {
  margin-bottom: 22px;
  font-weight: 600;
  color: #101828;
  font-size: 21px;
  line-height: 32px;
}

.footer .widget4 h5 {
  margin-bottom: 22px;
  font-weight: 600;
  color: #101828;
  font-size: 21px;
}

.copyRightArea {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.copyRightArea p {
  color: #96a1b6;
  text-align: right;
}

.block1 {
  width: 1440px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 56px;
}

.journey {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.journey-text {
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  box-sizing: border-box;
}
.logoo {
  margin: 0 0.2px 8px 0;
  display: flex;
  flex-direction: row;
  width: 107.8px;
  box-sizing: border-box;
}
.simplify {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  box-sizing: border-box;
}
.journey-header {
  margin: 0 0 16px 6.3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
}
.journey-heading {
  overflow-wrap: break-word;
  font-weight: 700;
  font-size: 48px;
  letter-spacing: -1px;
  color: var(--gray-900, #101828);
}
.images {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  /* height: 56vh; */
  height: 329.9px;
  box-sizing: border-box;
  padding-bottom: 6.5px;
}

.img-mid {
  border-radius: 8px 8px 0 0;
  position: absolute;

  bottom: 0px;
  width: fit-content;
  height: 329.9px;
}

.main-footer {
  display: flex;
  width: 1440px;
  padding: 80px 112px 56px 112px;
  flex-direction: column;
  align-items: flex-end;
  gap: 56px;
  border-top: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Gray-50, #f9fafb);
}

.footer-block {
  height: auto;
  width: 1128px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
}

.data-left-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  align-self: stretch;
}
.data-right-footer {
  display: flex;
  width: 182.5px;
  margin: 8px 0;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.frame-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.button-grey {
  width: 182.5px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.button-grey span {
  color: var(--Gray-800, #1d2939);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.frame-footer2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}
.all-rights {
  align-self: flex-end;
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #96a1b6;
  padding-right: inherit;
}

.left-section-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signup-left-section {
  width: 100%;
  height: 100vh;
  padding: 0.5rem 6rem;
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-logo {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 20px;
}

.logo {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 3rem; */
  margin-top: 20px;
}

.signup-right-section {
  width: 50%;

  height: inherit;
  background-color: #f8faff;
  object-fit: cover;
  margin: 0;
  padding: 0;
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
}
.signup-right-section img {
  width: 100%;
  height: 100vh;
}
.header {
  margin-bottom: 20px;
  text-align: center;
}

.progressbar {
  border-radius: 100px;
  background: #eaecf0;
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  height: 8px;
  box-sizing: border-box;
}
.bar {
  border-radius: 100px;
  background: var(--blue, #6595fb);
  width: 128px;
  height: 8px;
}
.task-container {
  width: auto;
  margin-top: 56px;
  height: 804px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 24px;
  padding: 0;
}
.heading h1 {
  margin-bottom: 8px;
  display: inline-block;
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--display-sm-semibold-font-weight, 600);
  font-size: var(--display-sm-semibold-font-size, 30px);
  line-height: var(--display-sm-semibold-line-height, 1.267);
  color: var(--gray-900, #101828);
}
.heading h4 {
  align-self: flex-start;
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--text-lg-regular-font-weight, 400);
  font-size: var(--text-lg-regular-font-size, 18px);
  line-height: var(--text-lg-regular-line-height, 1.556);
  color: var(--gray-600, #475467);
}
.input-field {
  width: 100% !important;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  box-sizing: border-box;
  gap: 4px;
}

.error-input-field {
  border: 1px solid var(--error-color) !important;
}
.error-input-field:focus {
  outline: none !important;
  border: 1px solid var(--error-color) !important;
}
.heading {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-sizing: border-box;
}

.email-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  padding: 9px 13.2px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
}
.email-input:focus {
  outline: 4px solid var(--input-outline-color) !important;
  border: 1px solid var(--darkblue-color) !important;
}

.password {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-sizing: border-box;
}
.password-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 384px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  padding: 9px 13.2px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
}
.placeholder {
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--text-md-regular-font-weight, 400);
  font-size: var(--text-md-regular-font-size, 16px);
  line-height: var(--text-md-regular-line-height, 1.5);
  color: var(--gray-400, #98a2b3);
  position: absolute;
  border: 1px solid (--error-color);
  cursor: text;
  transition: border-color 0.3s;
}
.input-field h5 {
  margin: 0 0.1px 8px 0.1px;
  display: inline-block;
  align-self: flex-start;
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--text-sm-medium-font-weight, 500);
  font-size: var(--text-sm-medium-font-size, 14px);
  line-height: var(--text-sm-medium-line-height, 1.429);
  color: var(--gray-900, #101828);
}
.input-field span {
  color: var(--Gray-600, #475467);
  text-align: start;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.sucess {
  display: flex;
  width: 128px;
  height: 128px;
  position: relative;
}
.continue-btn {
  border-radius: 8px;
  background: var(--blue, #6595fb);
  margin-bottom: 16px;
  display: flex;
  border: none;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
  font-weight: var(--text-md-semibold-font-weight, 600);
  font-size: var(--text-md-semibold-font-size, 16px);
  line-height: var(--text-md-semibold-line-height, 1.5);
  color: var(--white, #ffffff);
}

.continue-btn:hover {
  background-color: #0056b3;
}

.login-link {
  text-decoration: none;
  margin-bottom: 20px;
  overflow-wrap: break-word;
  font-weight: var(--text-lg-semibold-font-weight, 600);
  font-size: var(--text-lg-semibold-font-size, 18px);
  line-height: var(--text-lg-semibold-line-height, 1.556);
  color: var(--gray-800, #1d2939);
}

.continue-line {
  background: #eaecf0;
  width: 80px;
  height: 1px;
  align-self: center;
}
.continue-with-container {
  width: 100%;
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--text-md-regular-font-weight, 400);
  font-size: var(--text-md-regular-font-size, 16px);
  line-height: var(--text-md-regular-line-height, 1.5);
  color: var(--gray-500, #667085);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

.login-text {
  display: flex;
  justify-content: center;
}
.login-link:hover {
  color: var(--darkblue-color);
}
.login-text span {
  margin-right: 8.7px;
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--text-lg-regular-font-weight, 400);
  font-size: var(--text-lg-regular-font-size, 18px);
  line-height: var(--text-lg-regular-line-height, 1.556);
  color: var(--gray-800, #1d2939);
}
.social-icons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 20px; */
  gap: 15px;
}

.continue-container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  box-sizing: border-box;
  align-items: center;
}

.social-icon {
  width: 112px;
  height: 84px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  box-shadow: var(--2, 3px 4px 20px 0px rgba(112, 112, 112, 0.1));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  padding: 5px;
}
.social-icon img {
  width: 32px;
  height: 32px;
}

.social-icon h5 {
  margin-top: 5px;
  font-weight: var(--text-lg-medium-font-weight, 500);
  line-height: var(--text-lg-regular-line-height, 1.556);
  margin-bottom: 0;
}

.warning-sign img {
  width: 20px;
  height: 20px;
  align-items: end;
}
.email-input .placeholder {
  border: none;
  outline: none;
}

.warning-sign {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.verification-link {
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--display-sm-semibold-font-weight, 600);
  font-size: var(--display-sm-semibold-font-size, 30px);
  line-height: var(--display-sm-semibold-line-height, 1.267);
  color: var(--gray-900, #101828);
}
.didnt-text {
  margin-left: 10px;
  font-family: "Inter", sans-serif;
  color: var(--gray-800);
}
.link {
  margin-left: 10px;
  font-family: "Inter", sans-serif;
  color: var(--gray400-color);
  font-weight: var(--display-sm-semibold-font-weight, 600);
  cursor: pointer;
}
.timer {
  margin-left: 10px;
  font-family: "Inter", sans-serif;
}
.hint-text {
  margin-right: 4.2px;
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--text-sm-regular-font-weight, 400);
  font-size: var(--text-sm-regular-font-size, 14px);
  line-height: var(--text-sm-regular-line-height, 1.429);
  color: var(--gray-500, #667085);
  text-align: left;
}
.password {
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--text-md-regular-font-weight, 400);
  font-size: var(--text-md-regular-font-size, 16px);
  line-height: var(--text-md-regular-line-height, 1.5);
  position: absolute;
  border: 1px solid (--error-color);
  cursor: text;
  transition: border-color 0.3s;
}
.password-placeholder {
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--text-md-regular-font-weight, 400);
  font-size: var(--text-md-regular-font-size, 16px);
  line-height: var(--text-md-regular-line-height, 1.5);
  color: var(--gray-400);
  position: absolute;
  border: 1px solid var(--gray-500);
  cursor: text;
  transition: border-color 0.3s;
  border: none;
  outline: none;
}
.progressbar-mid {
  border-radius: 100px;
  background: #eaecf0;
  position: relative;
  margin-bottom: 40px;
  width: 384px;
  height: 8px;
  box-sizing: border-box;
}
.bar-mid {
  border-radius: 100px;
  background: var(--blue, #6595fb);
  width: 256px;
  height: 8px;
}
.progressbar-full {
  border-radius: 100px;
  background: #eaecf0;
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  height: 8px;
  box-sizing: border-box;
}
.bar-full {
  border-radius: 100px;
  background: var(--blue, #6595fb);
  width: 100%;
  height: 8px;
}
.checkbox-container {
  text-align: left;
  margin-top: 20px;
}
.i-agree {
  margin-top: 5px;
}

@keyframes shake {
  /* 0%, 100% {
    transform: translate(0, 0);
    border-color: transparent;
    background-color: transparent;
  }
  25%, 75% {
    transform: translate(-3px, -3px);
    border:2px solid #7bd8ff;
    background-color:  #c1edff;
  }
  50% {
    transform: translate(3px, 3px);
    border:2px solid #7bd8ff;
    background-color:  #c1edff;
    
  } */
  0%,
  100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
}

.shake-checkbox {
  animation: shake 0.5s ease-in-out 1;
  width: 100%;
}

.i-agree {
  color: #475467;
  font-weight: 400;
}
.i-agree a {
  color: #475467;
  font-weight: 400;
}
.i-agree-checked {
  color: #101828;
  font-weight: 400;
}
.checkbox-container input[type="checkbox"] {
  vertical-align: middle;
  margin-right: 10px;
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid #eaecf0;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}
.checkbox-container input[type="checkbox"]:checked {
  border-color: #6595fb;
  background: transparent;
}
.checkbox-container input[type="checkbox"]:checked::before {
  content: "";
  position: relative;
  display: block;
  width: 5px;
  height: 10px;
  border: solid #6595fb;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  top: 1px;
  left: 5px;
}
.checkbox-container label {
  font-size: 14px;
}
.loading-screen {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(196, 215, 255, 0.25),
    rgba(196, 215, 255, 0.1)
  );
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(26px);
  position: absolute;
  height: 100%;
}

.ring {
  width: 159px;
  height: 145px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: white;
  padding: 14px 21px;
  --tw-ring-color: none;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  width: 384px;
  height: 30px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #1d2939;
}

.modal {
  backdrop-filter: blur(26px);
  background: linear-gradient(
    180deg,
    rgba(196, 215, 255, 0.25),
    rgba(196, 215, 255, 0.1)
  );
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.splash-screen {
  background-color: #b5cdff;
  display: flex;
  width: 1440px;
  height: 800px;
  padding: 33px 353px 34px 354px;
  justify-content: center;
  align-items: center;
}
.loader-container {
  position: relative;
  width: 733px;
  height: 733px;
  flex-shrink: 0;
}

.loader {
  width: 209px;
  height: 209px;
  position: relative;
  border-radius: 50%;
  background: #fff;
  animation: wave 1s ease-in infinite;
}

.logo-middle {
  border-radius: 163px;
  background: #eff4ff;
  position: absolute;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 300px;
  height: 300px;
  transform: translate(-50%, -50%);
}

.logo-middle img {
  width: 180px;
  height: auto;
}
.forget-password {
  align-self: flex-start;
}
.forget-password a {
  align-self: flex-start;
  display: inline-flex;
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--text-sm-regular-font-weight, 400);
  font-size: var(--text-sm-regular-font-size, 14px);
  line-height: var(--text-sm-regular-line-height, 1.429);
  color: var(--gray-500, #667085);
  text-decoration: none;
}

.error-message {
  align-self: flex-start;
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--text-sm-regular-font-weight, 400);
  font-size: var(--text-sm-regular-font-size, 14px);
  line-height: var(--text-sm-regular-line-height, 1.429);
  color: var(--gray-600, #475467);
  margin-bottom: 24px;
}

.main-container {
  display: flex;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.active-button {
  display: flex;
  width: 100%;
  /* padding: 10px 0px 10px 16px; */
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  background-color: #f4f6f7;
}

.non-active-button {
  display: flex;
  width: 200px;
  padding: 10px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
}

.white-icon {
  width: 12px;
  height: 13.3px;
  color: solid 1.5px #fff;
}
.gray-icon {
  width: 13.3px;
  height: 13.3px;
  color: solid 1.5px #667085;
}

.active-button span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #344054;
}
.non-active-button span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #344054;
}

/* Main content area */
.main-content {
  margin-left: 16px;
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.nav-dashboard {
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #ffffff);
  display: flex;
  justify-content: space-between;
  padding: 20px 50px 19px 24.2px;
}
.nav-left {
  color: #1d2939;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}
.nav-right {
  display: flex;
  align-items: center;
  gap: 16px;
}
.divider {
  background: #eaecf0;
  width: 1px;
  height: 30px;
}
.bar-dashboard {
  border-radius: 100px;
  background: var(--blue, #6595fb);
  width: 376px;
  height: 8px;
}

.container-data {
  display: flex;
  /* width: 936px; */
  width: 80% !important;
  align-items: center;
  gap: 120px;
}
.text-side {
  margin: 0 20.3px 40px 0;
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-sizing: border-box;
}
.business-name {
  margin-bottom: 8px;
  display: inline-block;
  align-self: flex-start;
  overflow-wrap: break-word;
  font-family: "Inter", sans-serif;
  font-weight: var(--display-xs-semibold-font-weight, 600);
  font-size: var(--display-xs-semibold-font-size, 24px);
  line-height: var(--display-xs-semibold-line-height, 1.333);
  color: var(--gray-900, #101828);
}
.under-text {
  color: var(--Gray-600, #475467);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-align: left;
}
.error-details {
  color: var(--Gray-600, #475467);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.container-data {
  display: flex;
  width: 936px;
  align-items: center;
  gap: 120px;
}
.container-data {
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  width: fit-content;
  box-sizing: border-box;
  /* margin-top: 79px; */
  margin: 6rem;
}

.right-section {
  background: url(assets/dashboardSideImage.png) 50% / cover no-repeat;
  width: 456px;
  height: 450px;
  float: left;
  display: flex;
  border-radius: 8px;
}
.left-section {
  display: flex;
  width: 360px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex-shrink: 0;
  margin-left: 120px;
}
.dashbard-dropdown-container {
  height: 160px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 0;
}
.dashboard-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 10px 0px;
  border-radius: 8px;
}
.dropdown-heading {
  color: var(--Gray-900, #101828);
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  /* background-image: url("./assets/dropdownIcon.svg"); */
  background-repeat: no-repeat;
  background-position: right 10px center;
  width: 322px;
  height: 44px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  border-radius: 8px;
  border: solid 1px #eaecf0;
  background-color: #fff;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #98a2b3;
  overflow-y: auto;
  outline: none;
}

.custom-select-container .custom-select option {
  height: 44px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 10px 14px;
  border: 1px solid #eaecf0;
  background-color: #fff;
  width: 151px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101828;
  /* Enable scrolling */
  overflow-y: auto;
}

.custom-select-container .custom-select option:hover {
  background-color: #eaecf0;
  color: #000;
}
.custom-select-container.custom-select option:selected {
  color: #000;
}

.text-side {
  width: 456px;
  height: 96px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 0;
}
.Almost-done {
  height: 32px;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}
.choose-what-to {
  height: 48px;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #475467;
}

.choose-what-to .text-style-1 {
  font-weight: 600;
}
.Primary-Button {
  display: flex;
  width: fit-content;
  height: 44px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Blue, #6595fb);
  box-shadow: 3px 4px 20px 0px rgba(112, 112, 112, 0.1);
}
.Primary-Button {
  color: var(--White, #fff);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  display: flex;
  width: 264px;
  height: 44px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Blue, #6595fb);
  border: none;
}
.Primary-Button:active {
  background-color: #3676ff;
}

.Primary-Button:hover {
  background-color: #286dff;
}

.In-progress {
  width: 34px;
  height: 20px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  /* Added styles for flexbox */
  display: flex;
  align-items: center;
}

.secondary-Button {
  width: fit-content;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 8px;
  border: solid 1px #eaecf0;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #1d2939;
}

.secondary-Button:active {
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
}

.secondary-Button:hover {
  border: solid 1px #eaecf0;
  background-color: var(--white-color);
}
.secondary-Button img {
  width: 16px;
  height: 16px;
  flex-grow: 0;
  object-fit: contain;
}
.block {
  height: auto;
  width: 1128px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0;
}
.contentbox {
  height: 60px;
  width: 376px;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 8px;
  border: solid 1px #eaecf0;
  background-color: #fff;
}

.custom-checkbox-container {
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.small-button {
  width: fit-content;
  height: 28px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 100px;
  background-color: #f2f4f7;
}

/* Style the custom checkbox */
.custom-checkbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 2px solid #6595fb;
  border-radius: 3px;
  margin-left: 10px;
  font-size: 14px; /* Adjust font size as needed */
  text-align: center;
  line-height: 16px; /* Center the text vertically */
}

/* Show the checkmark when the checkbox is checked */
input[type="checkbox"]:checked + .custom-checkbox::before {
  content: "✔";
  color: #6595fb;
  font-weight: bold;
}
input[type="checkbox"]:not(:checked) + .custom-checkbox-container {
  justify-content: flex-start;
}
.Services-Text {
  color: var(--Gray-900, #101828);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-left: 0.5rem;
}
.add-guidence-popup {
  display: flex;
  width: 432px;
  min-height: auto;
  max-height: 500px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.big-plus-icon {
  border-radius: 1000px;
  background: #f8faff;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-start;
  padding: 8px;
  width: 64px;
  height: 64px;
  box-sizing: border-box;
}

.heading-and-supporting-text {
  height: 76px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 8px;
  padding: 0;
}

.text1 {
  width: 64px;
  height: 20px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #101828;
}
.Butons {
  height: 44px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 0;
}

.Dashboard-Cards {
  height: 136px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 0;
}

.icon-bg-file {
  width: 32px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 2px;
  background-color: #f5faff;
}
.icon-bg-file img {
  width: 16px;
  height: 16px;
  color: #6595fb;
}

.To-do {
  color: var(--Gray-600, #475467);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.number-date {
  height: 32px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
}

.Date {
  width: 73px;
  height: 14px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #667085;
}

.General-progress .General-progress {
  width: 784px;
  height: 152px;
  padding: 16px 23px 24px 17px;
  background-color: var(--white);
}

.General-progress .General-progress {
  width: 744px;
  height: 112px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 3px 4px 20px 0 rgba(112, 112, 112, 0.1);
  border: solid 1px var(--gray-200);
  background-color: var(--white);
}

.General-progress .Frame-928 {
  width: 170px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0;
}

.General-progress .Frame-427319867 {
  width: 32px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 2px;
  background-color: var(--primary-50);
}

.General-progress img.bar-chart {
  width: 16px;
  height: 16px;
  flex-grow: 0;
  object-fit: contain;
}

.General-progress .General-progress {
  width: 130px;
  height: 24px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--gray-600);
}

.General-progress progress {
  height: 24px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0;
}

.General-progress .content {
  height: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 8px;
  padding: 4px;
  border-radius: 100px;
  border: solid 1px var(--gray-200);
}

.General-progress span {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: var(--gray-500);
}
.General-progress .content .Background {
  height: 8px;
  align-self: stretch;
  flex-grow: 0;
  border-radius: 100px;
  background-color: var(--gray-200);
}

.doted-img {
  width: 565px;
  height: 527px;
  position: absolute;
  right: -229px;
  top: -197px;
  border-radius: 1000px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 255, 255, 0) 0%,
    #fff 100%
  );
  /* url(<path-to-image>) lightgray 50% / cover no-repeat; */
}

.loading-screen {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(196, 215, 255, 0.25),
    rgba(196, 215, 255, 0.1)
  );
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(26px);
  position: absolute;
  height: 100%;
}

.loading-modal {
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.progress-circle {
  width: 175px;
  height: 161px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
}

.loading-line {
  border: none;
  width: 117px;
  height: 117px;
  flex-grow: 0;
  background: url(assets/circle.png);

  border-width: 12px;
  filter: conic-gradient(
    from 0.25turn,
    #6595fb,
    rgba(101, 149, 251, 0) 0.49turn,
    rgba(101, 149, 251, 0.22) 0.74turn,
    #6595fb
  );
  border-image-slice: 1;
  animation: rotation 1s linear infinite;
}

@media only screen and (max-width: 768px) {
  .signup-left-section {
    width: 100%;
  }

  .signup-right-section {
    width: 100%;
  }
}

/* scroll bar styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 10px;
  height: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #838080;
}

.fake-loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #ccc;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.fake-loading-bar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #007bff;
  animation: fake-loading-progress 180s ease-out;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

@keyframes fake-loading-progress {
  0% {
    width: 0;
  }
  10% {
    width: 50%;
    animation-timing-function: ease-out;
  }
  20% {
    width: 60%;
    animation-timing-function: ease-out;
  }
  40% {
    width: 70%;
    animation-timing-function: ease-out;
  }
  100% {
    width: 100%;
    animation-timing-function: ease-out;
  }
}
