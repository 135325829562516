.missing-shipping-input-conatiner {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
}

.missing-shipping-label-styles {
    text-transform: capitalize;
    cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #344054;
}

.missing-shipping-input-styles {
    width: 100% !important;
    height: 44px !important;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 6px 12px;
    border-radius: 8px;
    border: solid 1px #d0d5dd;
    background-color: #fdfdfd;
}

.missing-shipping-input-error-styles {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #eb5757;
}