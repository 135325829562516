/* header */
.pro-header-container {
  background-color: #ff0000;
}

.pro-header-container p {
  text-align: center;
  padding: 1rem 2rem;
  margin: 0;
  letter-spacing: 0.1rem;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  color: white;
}

.pro-header-icon-container {
  grid-template-areas: "heading navigation icons";
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: 2rem;
  padding-top: 2rem;
  padding-left: 120px;
  padding-bottom: 2rem !important;
  border-bottom: 1px solid lightgrey;
}

/* price and heading section */
.product-card {
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 160px;
  width: 100%;
}

.pro-temp-product-image img {
  width: 100%;
  height: auto;
}

.pro-template-product-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pro-template-prim-heading {
  font-size: 34px;
  line-height: 44px;
  font-weight: 400;
  margin: 0;
  color: black;
}

.pro-template-second-heading {
  font-size: 34px;
  line-height: 44px;
  font-weight: 400;
  margin: 0;
  color: lightgrey;
}

.product-description {
  color: #b3b3b3;
  font-size: 1em;
}

.pro-temp-product-price {
  font-size: 1.2em;
}

.pro-temp-price-section {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.pro-temp-product-discount {
  font-size: 1em;
}

.pro-temp-discount-price {
  color: #6941c6;
}

.pro-temp-tax-info {
  color: #666;
  font-size: 0.9em;
}

.pro-temp-shipping {
  text-decoration: underline;
  cursor: pointer;
}

.pro-temp-sold-out-button {
  background-color: #6941c6;
  color: #fff;
  font-size: 1em;
  padding: 10px;
  border: none;
  cursor: not-allowed;
}

.pro-temp-payment-options {
  margin-top: 20px;
  font-size: 0.9em;
  color: #666;
  text-align: center;
}

.pro-temp-payment-icons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.pro-temp-payment-icons img {
  width: 40px;
  height: auto;
}

/* img */
.pro-template-carousel-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  /* margin-left: 20%; */
  margin-bottom: 15px;
}

.label-weight {
  font-weight: 600;
}

.pro-template-carousel-name {
  margin: 0;
  color: #00000080;
  font-size: 14px;
  line-height: 17px;
}

.pro-template-carousel-details {
  font-size: 19px;
  line-height: 26px;
  margin-top: 10px;
  color: black;
}

/* section images */
.pro-temp-section-image-container {
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* gap: 160px; */
  width: 100%;
  /* height: 250px; */
  align-items: center;
}

.pro-template-section-image {
  height: 330px;
}

.pro-template-detail-text {
  font-size: 16px;
  color: #000;
  line-height: 22px;
}

/* note component */
.pro-tem-note-container {
  background-color: #f7f6f5;
  min-height: 300px;
  margin-top: 66px;
  padding: 30px 30px;
}

.pro-temp-features-container {
  padding: 0 14.7px;
}

.pro-tem-note-text {
  cursor: pointer;
  font-size: 34px;
  font-style: italic;
  margin-bottom: 30px;
  margin-top: 0;
  line-height: 47px;
  text-align: left;
  color: #000;
}

.slider-container {
  cursor: pointer;
}

.pro-tem-note-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

/* pro-temp- */
.pro-tmp-heading {
  min-width: 100px;
  font-size: 34px;
  line-height: 44px;
  font-weight: 500;
  margin: 0 0 50px 0;
  color: #000;
  text-align: center;
  cursor: pointer;
}

.pro-tmp-feature-container {
  display: grid;
  gap: 20px;
  cursor: pointer;
  grid-template-columns: repeat(4, 1fr);
}

.pro-temp-feature-title-container {
 height: 100%;
  /* max-height: 100px; */
  overflow: hidden;
  border-bottom: 1px solid #000;
  margin-bottom: 17px;
}

.pro-temp-collapse-container {
  margin-top: 66px;
  padding: 0 50px 66px 50px;
}

.pro-tmp-feature-item h3 {
  color: #6941c6;
  font-size: 32px;
  margin-bottom: 10px;
  text-align: left;
  line-height: 46px;

  margin-top: 0;
}

/* moving text */
.pro-temp-scrolling-container {
  margin-top: 66px;
  width: 100%;
  overflow: hidden;
  background-color: #000;
  white-space: nowrap;
  padding: 40px 0;
}

.pro-temp-scrolling-text {
  display: inline-block;
  padding-left: 100%;
  animation: scroll 30s linear infinite;
}

.pro-temp-scrolling-text h1 {
  color: white;
  font-size: 40px;
  line-height: 1;
  margin-right: 20px;
  cursor: pointer;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* text on image */
.pro-temp-text-on-img {
  cursor: pointer;
}

.pro-temp-text-on-img h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #fff;
}

.pro-temp-text-on-img p {
  font-size: 21px;
  line-height: 36px;
  margin-bottom: 10px;
  color: #fff;
}

.pro-temp-text-container {
  background-color: #b3b3b3;
  width: 80%;
  padding: 20px;
  opacity: 0.8;
}

/* footer */
.pro-temp-footer__payment {
  display: flex;
  gap: 10px;
}

.pro-temp-icon-com-container {
  display: flex;
  justify-content: center;
  padding: 30px;
  border-top: 1px solid lightgrey;
}

.pro-footer-main-container {
  background-color: #f7f6f5;
}

.pro-footer-content {
  padding: 70px 160px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.pro-footer-need-help {
  font-size: 21px;
  line-height: 27px;
  color: #000;
}

.pro-temp-footer-links {
  font-size: 17px;
  color: rgba(18, 18, 18, 0.75);
}

.pro-temp-footer-links:hover {
  color: #000;
}

/* table styles */
.pro-temp-table-column-name {
  font-size: 18px;
  color: #2a2727;
  font-weight: 500;
  /* line-height: 22px; */
}

.pro-temp-table-feature-detail {
  font-size: 14px;
  color: #2a2727;
  font-weight: 400;
  line-height: 22px;
}

.pro-temp-table-second-bg {
  background-color: #f2f2f2;
}

.pro-temp-table-td {
  padding: 15px;
  border-bottom: 1px solid #ebeaea;
}

.slick-prev:before,
.slick-next:before {
  content: '';
}
