.products-table-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 24px;
}

.products-number-container {
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  border-radius: 24px;
  background-color: #f2f4f7;
  margin-left: 5px;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
}

.tab-label-container {
  display: flex;
  align-items: center;
}
.tab-label-container div {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
}

.ant-tabs-ink-bar,
.ant-tabs-ink-bar-animated {
  background-color: #6941c6 !important;
}

.import-product-btn-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0px 24px 0px;
}

.import-product-btn {
  width: 158px;
  height: 38px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #7f56d9;
  background-color: #7f56d9;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.import-product-btn:hover {
  background-color: #6941c6;
}

.upgrade-plan-btn {
  width: 158px;
  height: 38px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #7f56d9;
  background-color: white;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #5017d3;
}

.upgrade-banner.error .upgrade-plan-btn {
  border: solid 1px #fecdca;
  color: #b42318;
  box-shadow: 0px 1px 2px 0px #2811100d;
  box-shadow: 0px 1px 2px 0px #2812100d;
}

.upgrade-status {
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 16px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: center;
  justify-content: left;
}
.upgrade-status:has(div.button) {
  cursor: pointer;
  padding: 4px 8px 4px 12px;
}

.upgrade-status.regular {
  background: #f9f5ff;
  color: #6941c6;
}
.upgrade-status.error {
  background: #fef3f2;
  color: #b42318;
}

.upgrade-status .button {
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 16px;
  border: 1px solid;
  line-height: 18px;
  font-size: 12px;
  font-weight: 500;
  background-color: #ffffff;
}
.upgrade-status.regular .button {
  border-color: #6941c6;
}
.upgrade-status.error .button {
  border-color: #fecdca;
}

.upgrade-status.regular .arrow-icon {
  filter: brightness(0) saturate(100%) invert(15%) sepia(56%) saturate(7133%)
    hue-rotate(258deg) brightness(105%) contrast(96%);
}
.upgrade-status.error .arrow-icon {
  filter: brightness(0) saturate(100%) invert(32%) sepia(49%) saturate(4301%)
    hue-rotate(344deg) brightness(103%) contrast(88%);
}

.upgrade-plan-icon {
  filter: brightness(0) saturate(100%) invert(26%) sepia(11%) saturate(6400%)
    hue-rotate(231deg) brightness(115%) contrast(104%);
}

.ant-table-thead th {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #475467 !important;
}

/* Custom styles for table body text */
.ant-table-tbody td {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #3e444c;
  border-bottom: 1px solid #264f78;
}

/* no data component styling */

.no-data-component-main-container {
  height: 302px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-data-component-main-container h4 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #373b42;
}

.no-data-component-main-container p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #4b5563;
}

.select-template-button {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 0px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #c6b1f5;
  background-color: #f9f5ff;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #6941c6;
}
.select-template-button.selected {
  background-color: #7f56d9;
  color: #fff;
}

/*.modal-import-button-disabled {
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;
  border: solid 1px #7f56d9 !important;
  background-color: #7f56d9 !important;
  opacity: 0.8 !important;
  color: #fff !important;
}*/

.modal-import-button:hover {
  border: solid 1px #6941c6 !important;
  background-color: #6941c6 !important;
}

.modal-import-button {
  width: 190px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 0px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #7f56d9;
  background-color: #7f56d9;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.modal-import-button-disabled {
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;
  border: solid 1px #7f56d9 !important;
  background-color: #7f56d9 !important;
  opacity: 0.8 !important;
  color: #fff !important;
}

.modal-import-button:hover {
  border: solid 1px #6941c6 !important;
  background-color: #6941c6 !important;
}

.product-modal-cancel-button {
  width: 190px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 0px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #d0d5dd;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #344054;
}
.product-modal-cancel-button-disabled {
  width: 190px !important;
  height: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  padding: 10px 0px !important;
  border-radius: 8px !important;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;
  border: solid 1px #d0d5dd !important;
  background-color: #fff !important;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #344054 !important;
  opacity: 0.8 !important;
}

.product-modal-cancel-button:hover {
  color: #6941c6 !important;
  border: solid 1px #d0d5dd !important;
  background-color: #dddae3 !important;
}

.add-product-modal-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}
.add-prduct-content-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.delete-product-modal-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 5px;
  margin-bottom: 20px;
}

.delete-prduct-modal-content {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.delete-prduct-modal-content h4 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}

.delete-prduct-modal-content p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #475467;
}
.add-prduct-content-container h4 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}
.delete-btn-delete-prod-modal {
  background-color: #ba382f !important;
  width: 50%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 18px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.delete-btn-delete-prod-modal:hover {
  background-color: #b42318 !important;
}
.delete-btn-delete-prod-modal-disabled {
  color: lightgrey !important;
}
.add-prduct-content-container p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #475467;
}

.modal-input-label {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #344054;
}

.input-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
}
.form-control {
  height: 44px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 14px;

  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #d0d5dd;
  background-color: #fff;
}

.form-control:focus {
  border: solid 2px #6941c6;
  outline: none;
  box-shadow: none;
}

.add-product-modal-warning {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #eb5757;
  padding: 4px 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.custom-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.custom-spin-icon {
  font-size: 64px;
  color: #6941c6;
}

.product-active-status {
  width: 85px;
  height: 28px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 4px 12px;
  border-radius: 16px;
  background-color: #ecfdf3;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #027a48;
}

.product-draft-status {
  height: 30px;
  width: 111px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 4px 6px 4px 12px;
  border-radius: 16px;
  background-color: #eff8ff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #175cd3;
}
.product-draft-status-edit {
  height: 22px;
  width: 53px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 2px 6px 2px 8px;
  border-radius: 16px;
  border: solid 1px #b1caf5;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #175cd3;
}
.product-delete-btn {
  height: 30px;
  width: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 2px 6px 2px 8px;
  border-radius: 16px;
  border: solid 1px #e7918b;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #175cd3;
}
.product-delete-btn:hover {
  border: solid 1px #b42318;
}
.product-title-image-container {
  width: 40px;
  height: 40px;
  background-color: #d9d9d9;
  border-radius: 8px;
}

.product-title-image-container img {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border-color: #d9d9d9;
  background-color: #d9d9d9;
}

.product-column-title-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
