.gallery-image-edit-container-variants {
  /* width: 150px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  border: solid 1px #cad1d7;
height: 100%;
  border-radius: 8px;
  background-color: transparent;
  /* margin-bottom: 8px; */
}

.variants-edit-control-container {
  height: 32px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  justify-content: space-between;
  align-items: center;
  gap: 7px;
  padding: 0px 8px 0 8px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #e3e6ea;
  background-color: #fff;
}

.variants-edit-control-container p {
  align-self: stretch;
  flex-grow: 1;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}

.variants-edit-control-container img {
  width: 20px;
  height: 15px;
  cursor: pointer;

}

.variants-edit-control-container span {
  width: 1px;
  height: 20px;
  flex-grow: 0;
  background-color: #cad1d7;

}
 
.variant-canva-text-edit {
  font-size: 9px;
}
 
.variant-canva-text-edit {
  font-size: 8px;
}

 
@media (min-width: 1280px) {
  .variant-canva-text-edit {
    font-size: 8px;
  }
  .variant-canva-text-sync{
    font-size: 6px;
  }
}

@media (min-width: 1920px) {
  .variant-canva-text-edit {
    font-size: 12px;
  }
  .variant-canva-text-sync {
    font-size: 9px;
  }
}
