.replace-modal-heading {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #3e444c;
    margin-bottom: 8px;
}

.replace-modal-para {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #4b5563;
    margin-bottom: 4px;

}

.footer-container {
    width: 100%;
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.currently-handled {
    width: 184px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    border-radius: 24px;
    background-color: #e3e6ea;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #344054;
}

.replace-modal-order-id {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #373b42;
}

.replace-modal-order-details {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #4b5563;
}

.replace-img-container {
    width: 60px;
    height: 60px;
    align-self: stretch;
    flex-grow: 0;
    border-radius: 8px;
    background-color: #e3e6ea;

}

 .ant-checkbox-disabled .ant-checkbox-inner:after{
border-color: white !important;
}
 .ant-checkbox-disabled .ant-checkbox-inner{
background-color: #798797 !important;
border: 1px solid #798797 !important;
}
.ant-modal-content{
    padding: 20px 0 !important;
}
 