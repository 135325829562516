.product-edit-view-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f4f6f7;
}

.outline-border {
  border: dotted 2px #6100ff !important;
}
.edit-formatted-line p {
  color: white;
  font-size: 18px !important;
}
.top-bar-main-container {
  height: 64px;
  display: flex;
  position: fixed;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: solid 1px #e3e6ea;
  background-color: #fff;
  z-index: 1;
}
.steps-buttons {
  display: flex;
  gap: 5px;
  align-items: center;
}
.top-bar-main-container-white-button {
  flex-direction: row;
  height: 36px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #d0d5dd;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #344054;
}

.top-bar-main-container-white-button:hover {
  background-color: #f4f6f7;
}

.top-bar-main-container-middle-button {
  width: 150px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  border: solid 1px #d5c3fd;
  background-color: #f9f5ff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #6941c6;
}
.top-bar-main-container-middle-buttons {
  /* width: 197px; */
  height: 36px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  border: solid 1px #d5c3fd;
  background-color: #f9f5ff;
}
.top-bar-main-container-middle-buttons p {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;

  margin: 0;
  color: #6941c6;
}
.top-bar-main-container-middle-buttons-override {
  height: 36px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  border: solid 1px #cad1d7;
  background-color: #fff;
}
.top-bar-main-container-middle-buttons-override p {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;

  margin: 0;
  color: #3e444c;
}
.top-bar-main-container-middle-buttons-vector {
  width: 24px;
  height: 2px;
  border-radius: 100px;
  background-color: #cad1d7;
}

.top-bar-main-container-middle-button:hover {
  background-color: rgb(234, 231, 237);
}

.top-container-right-btns-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.top-container-right-btns-container-button {
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #7f56d9;
  background-color: #7f56d9;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.top-container-right-btns-container-button:hover {
  border: solid 1px #6941c6;
  background-color: #6941c6;
}

.edit-product-content-container {
  display: flex;
  flex-direction: row;
}
.delete-prduct-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
  min-height: 250px;
}
.delete-prduct-content-container-img {
  font-size: 60px;
  color: #e4c673;
  margin-bottom: 30px;
}

.delete-prduct-content-container h4 {
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}

.delete-prduct-content-container p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #475467;
}
.product-main-left-div {
  display: flex;
  width: 75%;
  flex-direction: column;
  margin-top: 64px;
  padding-right: 20px;
}

.product-main-left-container {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  padding: 51.4px 0 0;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.3);
  border-radius: 10px;
}

.edit-product-first-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 38px;
  padding: 0px 14.7px 90px;
}

.product-middle-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 66px;
  padding: 0 14.7px;
}

.first-content-box-main-container {
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  /* background-color: #85888f; */
}
.first-content-box-left-container {
  display: flex;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 15px;
  gap: 35px;
  background-color: #1e1f21;
}
.second-content-box-left-container {
  display: flex;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 15px;
  gap: 35px;
  border: solid 1px #f2f1f5;
  background-color: #fff;
}

.first-container-left-box {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 35px;
}

.second-container-left-box {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 35px;
}
.second-container-left-box h3 {
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #373b42;
  margin: 0 0 14px;
}
.second-container-left-box div {
  max-width: 380px;
  font-family: "Inter", sans-serif;
  font-size: 18px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #373b42;
}
.second-container-left-box div p {
  font-size: 18px !important;
}

.first-container-left-box h3 {
  font-family: "Inter", sans-serif;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.edit-formatted-text {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  white-space: pre-wrap;
}
.edit-formatted-text div {
  font-size: 18px !important;
}
.edit-formatted-text div p {
  font-size: 18px !important;
}
.edit-formatted-text p {
  font-size: 18px !important;
}
.edit-formatted-line {
  margin: 0;
  padding-bottom: 10px;
  font-size: 18px !important;
}

.first-content-box-right-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.first-content-box-right-container-image {
  width: 100%;
  min-height: 400px;
  height: 100%;
  object-fit: cover;
}

.third-container-left-box {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product-bullet-point-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 14.7px;
  padding: 0;
  margin: 0 0 22px;
}
.productbullet-point-icon {
  height: 45px;
  width: 45px;
  object-fit: contain;
}

.product-bullet-point-text {
  font-family: "Inter", sans-serif;
  font-size: 18px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}

.fourth-container-left-box {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 35px;
}

.fourth-container-left-box h3 {
  font-family: "Inter", sans-serif;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #373b42;
}

.fourth-container-left-box p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}

.product-shop-now-button-black {
  width: 216px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 63px;
  background-color: #373b42;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  align-self: left;
}

.product-shop-now-button-black:hover {
  opacity: 0.9;
  font-weight: 600;
}

.fifth-content-box-left-container {
  display: flex;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 15px;
  gap: 35px;
  border: solid 1px #f2f1f5;
  background-color: #fff;
}

.product-footer-main-continer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 56px;
  padding: 66px 21px;
  width: 100%;
}
.product-footer-img-container {
  width: 250px;
  height: auto;
  min-height: 250px;
  /* background-color: #85888f; */
}
.product-footer-image {
  height: auto;
  width: 250px;
  /* background-color: #85888f; */
}

.product-footer-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align:  center;
  gap: 29px;
  width: 100%;
  max-width: 612px;
}

.product-footer-main-continer h3 {
  font-family: "Inter", sans-serif;
  font-size: 24.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}

.product-footer-main-continer p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #373b42 !important;
}

.image-edit-container {
  position: relative;
  height: 381px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-self: stretch;
  flex-grow: 0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: #85888f;
}
.background-image-overlay-edit-image-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: rgba(128, 128, 128, 0.5);
}

.image-edit-button {
  width: 44px;
  height: 44px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #cad1d7;
  background-color: #fff;
}
.edit-image-upper-portion {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.edit-image-lower-portion {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.image-delete-button {
  width: 44px !important;
  height: 44px !important;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #fee4e2;
  background-color: #fef3f2;
}

.image-edit-button:hover,
.image-delete-button:hover {
  opacity: 0.8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.canva-button-edit {
  height: 44px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 10px;
  border-radius: 8px;
  border: solid 1px #cad1d7;
  background-color: #fff;
  color: #373b42;
  font-weight: 500;
  font-size: 15px;
}
.canva-button-edit.small {
  height: 32px;
  padding: 4px 6px;
  font-size: 12px;
  letter-spacing: -0.5px;
}
.canva-button-edit:hover:not(:has(> .loader-class)) {
  background-color: #f4f6f7;
  /*opacity: 0.8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);*/
}
.canva-button-edit:has(> .loader-class) {
  cursor: not-allowed;
}
.canva-button-edit.small:has(> .loader-class) {
  cursor: not-allowed;
  padding: 4px 2px;
  word-spacing: -1px;
  letter-spacing: -1px;
}

.edit-image-upper-portion:has(> .canva-button-edit) {
  display: flex;
  justify-content: space-between;
}

/* .top-bar-main-container {
  position: relative;
} */

.buttons-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}
.variants-count-error-message {
  position: relative;
  top: 5px;
  font-size: 12px;
  font-weight: 500;
  left: 10px;
  padding: 10px;
  text-align: center;
}

.variants-count-error-message-detail-page {
  position: relative;
  top: -10px;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  padding: 10px;
  text-align: center;
}
.pro-temp-section-head{
  color:#000 !important;
  font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
 
}
.pro-temp-table-switch-styles {
  width: 50px;
}
