.wrapperChild {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 25px 24px;
}
.sider-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
}
.logout-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  padding: 8px 12px !important;
}
.logout-row:hover {
  background-color: #f4f6f7;
  border-radius: 6px;
}
.logout-row span {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #344054;
  opacity: 1;
}
.logout-row span .collapsed {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.top-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}
.top-header-container h3 {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}

.ant-menu-item .ant-menu-item-selected {
  background-color: #f4f6f7 !important;
  border-radius: 6px !important;
  gap: 16px;
  padding: 8px 12px;
}
.ant-menu-item {
  gap: 12px;
  padding: 8px 12px !important;
  border-radius: 6px;
  margin-inline: 0px !important;
  background-color: #fff;
  width: 100% !important;
}
.ant-menu-item-selected .ant-menu-title-content {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #344054;
}
:where(.css-dev-only-do-not-override-kghr11).ant-menu
  .ant-menu-item
  .ant-menu-item-icon
  + span,
:where(.css-dev-only-do-not-override-kghr11).ant-menu
  .ant-menu-submenu-title
  .ant-menu-item-icon
  + span,
:where(.css-dev-only-do-not-override-kghr11).ant-menu
  .ant-menu-item
  .anticon
  + span,
:where(.css-dev-only-do-not-override-kghr11).ant-menu
  .ant-menu-submenu-title
  .anticon
  + span {
  margin-inline-start: 0px !important;
}
/* .ant-btn-icon img {
  width: 24px !important;
  height: 24px !important;
} */
.ant-menu-title-content {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #344054;
}
.ant-menu,
.ant-menu-root,
.ant-menu-light {
  display: flex;
  flex-direction: column;
  border: none !important;
}

.profile-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  padding-top: 24px;
  border-top: 2px solid #e6e6e6;
}
.profile-container .profile-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.profile-content .profile-name {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #3e444c;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  opacity: 1;
}

.profile-content .profile-username {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #4b5563;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  opacity: 1;
  max-width: 150px;
}

.profile-content .collapsed {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.profile-content .notConnected {
  color: red;
}

.ant-menu .ant-menu-item .ant-menu-item-icon {
  display: block !important;
  min-width: none !important;
  font-size: none !important;
  transition: none !important ;
  max-width: none !important;
}
.footer-text{
  cursor: pointer;
  font-weight: 500;
  color: #798797;
}
.footer-container{

}
